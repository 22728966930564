import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"

const span = css``
const Champion = ({ registryId, description }) => {
  return (
    <div
      css={css`
        display: flex;
        & span:first-of-type {
          margin-right: 0.5rem;
        }
      `}
    >
      <span>
        <a
          href={`http://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=${registryId}`}
        >
          {registryId}
        </a>
      </span>
      <span>{description}</span>
    </div>
  )
}

Champion.propTypes = {
  registryId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Champion
